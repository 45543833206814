<template>
  <div class="container ml-quiz-layout-preview" v-if="detail">
    <div class="breadcrumd-custom"><nuxt-link to="/bo-de">Bộ đề</nuxt-link> <img src="~/mlearn/icons/breadcrumd.svg" alt="" class="icon" /> <span class="active">Kết quả</span></div>
    <div class="quiz-success text-center font-weight-bold m-auto">
      Chúc mừng bạn đã hoàn thành xong bộ đề <br class="d-none d-lg-block">{{ detail.name }} !
    </div>
    <div class="row quiz-content">
      <div class="col-sm-9">
        <div class="quiz-list-question" ref="quiz-list-question">
          <div class="quiz-question-item font-weight-bold quiz-note-primary">
            Bài làm của bạn <span class="font-weight-normal">(Đáp án bạn chọn được tô màu xanh dương).</span>
          </div>
          <Question v-for="(item,index) in detail.questions" :key="item.id" :item="item" :index="index" :exam-id="detail.id" type-question="view"/>
        </div>
      </div>
      <div class="col-sm-3 quiz-list-short" ref="quiz-list-short">
        <div ref="quiz-list-short-sticky">
          <div class="quiz-point mt-3 mt-lg-0 mb-3">
            <div class="quiz-point-show px-3">Điểm số: <span>{{detail.user_score}}/{{detail.score}}</span></div>
            <div class="quiz-point-line"></div>
            <div class="quiz-point-time-exam">Số câu đã làm: <span>{{detail.number_answered}}/{{detail.number_question}}</span></div>
            <div class="quiz-point-time-exam pt-1 pt-lg-2">Thời gian làm bài: <span>{{detail.completion_time}}/{{ detail.time }} phút</span></div>
            <div class="row no-gutters">
              <div class="col-sm-6 pt-2 pt-lg-3">
                <div class="d-flex align-items-center">
                  <span class="quiz-point-status success"></span>Đúng:<span class="quiz-point-status-number">{{ detail.number_correct }}</span>
                </div>
              </div>
              <div class="col-sm-6 pt-2 pt-lg-3">
                <div class="d-flex align-items-center">
                  <span class="quiz-point-status fail"></span>Sai:<span class="quiz-point-status-number">{{detail.number_incorrect}}</span>
                </div>
              </div>
              <div class="col-sm-12 pt-2 pt-lg-3">
                <div class="d-flex align-items-center">
                  <span class="quiz-point-status none"></span>Chưa làm:<span class="quiz-point-status-number">{{ detail.number_not_answered }}</span>
                </div>
              </div>
            </div>
          </div>
          <div :class="quizSticky ? 'quiz-sticky' : 'quiz-sticky-static'" :style="{width: quizSticky ? ($refs['quiz-list-short'].clientWidth - 10 )+'px' : '100%'}">
            <div class="quiz-list-short-form">
              <div class="title text-uppercase text-center">Câu hỏi</div>
              <div class="quiz-list-question-short scrollbar">
                <div class="custom-row">
                  <div class="custom-col-2 quiz-question-answer" v-for="(question,index) in detail.questions" :key="question.id" @click="scrollToQuestion(index)" :class="question.is_answered ? (question.is_correct ? 'success' : 'fail') : 'none'">
                    <span class="position-relative float-left d-inline-block number-answer">
                      <span class="number-answer-character pointer" :class="{'big-answer': detail.questions.length > 99}">{{ index + 1 }}</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="quiz-action-restart">
              <nuxt-link :to="'/bo-de/lam-bai/'+$route.params.slug">
                <button>Làm lại</button>
              </nuxt-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="quiz-relation" v-if="relations.length">
      <div class="quiz-relation-title">Có thể bạn quan tâm</div>
      <div class="quiz-relation-slider">
        <VueSlickCarousel v-bind="carouselSettings">
          <QuizItem v-for="item in relations" :key="item.id" :item="item" :action="false"/>
          <template #prevArrow>
            <ArrowLeft />
          </template>
          <template #nextArrow>
            <ArrowRight />
          </template>
        </VueSlickCarousel>
      </div>
    </div>
    <PlaylistNotify :flag-notify="flagNotify" :text-notify="textNotify" @hide="flagNotify = false" />
  </div>
</template>

<script>
import cookieParser from 'cookieparser'
import VueSlickCarousel from 'vue-slick-carousel'
import Question from '~/components/mlearn/quiz/question.vue'
import QuizItem from '~/components/mlearn/quiz/item.vue'
import PlaylistNotify from '~/components/mlearn/playlist/notify.vue'
import Resource from '~/common/api/resource'
const examResultResource = new Resource('library/exam/result')
const examRelationResource = new Resource('library/exam/related')
export default {
  components: {
    Question,
    VueSlickCarousel,
    QuizItem,
    PlaylistNotify
  },
  layout: 'mlearn',
  middleware: 'authenticated',
  async asyncData ({ isDev, route, store, env, params, query, req, res, redirect, error }) {
    let token = ''
    if (req && req.headers && req.headers.cookie) {
      const requestCookies = cookieParser.parse(req.headers.cookie)
      token = requestCookies.access_token || ''
    }
    const tmp1 = await examResultResource.list({ exam_slug: params.slug }, token)
      .catch(() => {
        return error({ statusCode: 404, title: 'Bộ đề không tồn tại', message: 'Bộ đề đã bị xóa hoặc bị báo cáo vi phạm.' })
      })
    if (!tmp1) {
      return error({ statusCode: 404, title: 'Bộ đề không tồn tại', message: 'Bộ đề đã bị xóa hoặc bị báo cáo vi phạm.' })
    }
    if (!tmp1.data) {
      return error({ statusCode: 404, title: 'Bộ đề không tồn tại', message: 'Bộ đề đã bị xóa hoặc bị báo cáo vi phạm.' })
    }
    const detail = tmp1.data
    const tmp2 = await examRelationResource.list({ exam_id: detail.id }, token)
    const relations = tmp2.data
    return {
      detail,
      quizSticky: false,
      processing: false,
      carouselSettings: {
        dots: false,
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2
            }
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 1.2
            }
          }
        ]
      },
      relations,
      flagNotify: false,
      textNotify: '',
      breakFix: 0
    }
  },
  beforeMount () {
    window.addEventListener('scroll', this.handerScroll)
  },
  mounted () {
    window.addEventListener('scroll', this.handerScroll)
    this.handerScroll()
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handerScroll)
  },
  methods: {
    handerScroll () {
      if (!this.breakFix) {
        this.breakFix = document.querySelector('.quiz-list-short-form').getBoundingClientRect().top
      }
      const width = window.innerWidth
      const top = window.scrollY
      if (top < this.breakFix - 100 || width < 576) {
        this.quizSticky = false
        return
      }
      this.quizSticky = true
      if (document.querySelector('.quiz-relation')) {
        const offsetRelation = document.querySelector('.quiz-relation').offsetTop
        if (top >= offsetRelation - 200) {
          this.quizSticky = false
          return
        }
      }
      const offsetFooter = document.querySelector('footer').offsetTop
      if (top >= offsetFooter) {
        this.quizSticky = false
      }
    },
    scrollToQuestion (index) {
      const elmnt = document.getElementById('question-number-' + index)
      elmnt.scrollIntoView({ block: 'center', behavior: 'smooth' })
    }
  }
}
</script>

<style lang="scss" scoped>
  .ml-quiz-layout-preview{
    position: relative;
    .quiz-success{
      color: var(--primary);
      width: 615px;
      max-width: 100%;
      font-size: 18px;
      @media (max-width:576px) {
        font-size: 16px;
      }
    }
    .quiz-content{
      padding-top: 16px;
      padding-bottom: 150px;
      @media (max-width:576px) {
        padding-bottom: 30px;
      }
      .quiz-note-primary{
        font-size: 20px;
        padding: 24px 24px 10px 24px;
        background: var(--white);
        color: var(--primary);
        @media (max-width:576px) {
          padding: 16px 16px 0;
        }
      }
      .quiz-list-question{
        background: var(--white);
        box-shadow: 0px 2px 4px rgba(174, 174, 174, 0.34);
        border-radius: 8px;
        .quiz-question-item{
          border-bottom:1px solid #C4C4C4;
          &:first-child,&:last-child{
            border-bottom:0;
          }
        }
      }
      .quiz-list-short{
        .quiz-sticky{
          position: fixed;
          top: 90px;
          z-index: 999;
        }
        .quiz-sticky-static
        {
          position: static;
        }
        .quiz-point{
          background: var(--white);
          box-shadow: 0px 2px 4px rgba(174, 174, 174, 0.34);
          border-radius: 8px;
          padding:16px 20px;
          @media (max-width:576px) {
            padding:16px;
          }
          .quiz-point-show{
            color: var(--danger);
            font-size: 20px;
            @media (max-width:576px) {
              font-size: 18px;
            }
            span{
              font-weight: bold;
              font-size: 26px;
              padding-left:10px;
              @media (max-width:576px) {
                font-size: 20px;
                padding-left:5px;
              }
            }
          }
          .quiz-point-line{
            border: 1px solid #EBEBEB;
            margin:10px 0 16px;
            @media (max-width:576px) {
              margin:10px 0;
            }
          }
          .quiz-point-time-exam{
            font-weight: 300;
            font-size: 16px;
            @media (max-width:576px) {
              font-size: 14px;
            }
            span{
              font-weight: bold;
              font-size: 20px;
              @media (max-width:576px) {
                font-size: 18px;
              }
            }
          }
          .quiz-point-status{
            border-radius: 50%;
            margin-right: 10px;
            display: inline-block;
            font-weight: 300;
            font-size: 16px;
            width: 24px;
            height: 24px;
            @media (max-width:576px) {
              width: 20px;
              height: 20px;
            }
            &.success{
              background: #64CB5B;
            }
            &.fail{
              background: var(--danger);
            }
            &.none{
              background: #E3E3E3;
            }
          }
          .quiz-point-status-number{
            font-weight: bold;
            padding-left:5px;
            font-size: 18px;
            @media (max-width:576px) {
              font-size: 16px;
            }
          }
        }
        .quiz-list-short-form{
          background: var(--white);
          box-shadow: 0px 2px 4px rgba(174, 174, 174, 0.34);
          border-radius: 8px;
          .title{
            background: var(--primary);
            box-shadow: 0px 2px 4px #D8DDE6;
            border-radius: 8px 8px 0px 0px;
            font-weight: bold;
            color: var(--white);
            font-size: 20px;
            padding:13px 0;
            @media (max-width:576px) {
              font-size: 18px;
              padding:10px 0;
            }
          }
          .quiz-list-question-short{
            min-height: 20vh;
            max-height: 40vh;
            overflow-x: hidden;
            overflow-y: auto;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            padding:14px 7px 32px;
            @media (max-width:576px) {
              padding:10px 7px 20px;
            }
            .custom-row{
              display: flex;
              flex-wrap: wrap;
              > [class*="custom-col-"] {
                padding-top:10px;
                padding-right: 12px;
                padding-left: 12px;
                @media (max-width:576px) {
                  padding-right: 6px;
                  padding-left: 6px;
                }
              }
              .custom-col-2{
                --percent: 20%;
                flex: 0 0 var(--percent);
                max-width: var(--percent);
                @media (max-width:576px) {
                  --percent: 16.6666%;
                }
              }
            }
            .quiz-question-answer{
              .number-answer{
                background: #E3E3E3;
                border-radius: 50%;
                cursor: pointer;
                height:38px;
                width:38px;
                @media (max-width:576px) {
                  height:35px;
                  width:35px;
                }
                .number-answer-character{
                  position: absolute;
                  top:50%;
                  left:50%;
                  transform: translate(-50%,-50%);
                  font-size: 16px;
                  @media (max-width:576px) {}
                  &.big-answer{
                    font-size: 14px;
                  }
                }
              }
              &.primary{
                .number-answer{
                  background: var(--primary);
                  .number-answer-character{
                    color: var(--white);
                  }
                }
              }
              &.success{
                .number-answer{
                  background: #64CB5B;
                  .number-answer-character{
                    color: var(--white);
                  }
                }
              }
              &.fail{
                .number-answer{
                  background: var(--danger);
                  .number-answer-character{
                    color: var(--white);
                  }
                }
              }
            }
          }
        }
        .quiz-action-restart{
          text-align: center;
          padding-top:24px;
          @media (max-width:576px) {
            padding-top:16px;
          }
          button{
            background: var(--primary);
            border: 1px solid var(--primary);
            border-radius: 8px;
            color: var(--white);
            font-weight: 500;
            font-size: 16px;
            text-align: center;
            width:100%;
            padding:10px 38px;
            @media (max-width:576px) {
              padding:10px 38px;
            }
          }
        }
      }
    }
  }
</style>
<style lang="scss">
  .ml-quiz-layout-preview{
    .quiz-relation{
      margin-bottom:5rem;
      @media (max-width:576px) {
        margin-bottom:2rem;
      }
      .quiz-relation-title{
        font-weight: 500;
        font-size: 20px;
        @media (max-width:576px) {}
      }
      .quiz-relation-slider{
        padding-top:10px;
        .slick-slider{
          .slick-list{
            margin: 0 -6px;
            .slick-track{
              .slick-slide{
                padding:10px 6px;
                @media (max-width:576px) {}
              }
            }
          }
        }
      }
    }
  }
</style>

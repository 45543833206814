<template>
  <div class="ml-blog mb-5">
    <BlogBanner/>
    <div class="container blog-list" ref="blog-list">
      <BlogItem v-for="(item, ind) in listData" :key="item.id" :topic="ind === 0" :item="item"/>
    </div>
    <NoData v-if="!listData.length" />
    <Pagination class="mt-3" :total="query.total" :value="query.page" :limit="query.limit" @change="paginationData" />
  </div>
</template>
<script>
import Pagination from '~/components/mlearn/pagination/index'
import BlogBanner from '~/components/mlearn/blog/banner.vue'
import BlogItem from '~/components/mlearn/blog/item.vue'
import Resource from '~/common/api/resource'
const metaResource = new Resource('config/seo')
const blogArticleResource = new Resource('blog/article')
export default {
  components: {
    BlogBanner,
    BlogItem,
    Pagination
  },
  layout: 'mlearn',
  async asyncData ({ isDev, route, store, env, params, req, res, redirect, error }) {
    if (route.query.slug) {
      if (/blog-app/.test(route.name)) {
        return redirect('/blog-app/' + route.query.slug)
      } else {
        return redirect('/blog/' + route.query.slug)
      }
    }
    let page = route.query.page || 1
    page = parseInt(page)
    if (page <= 0) { page = 1 }
    const query = { page, limit: 7, offset: 0, total: 0 }
    const offset = (page - 1) * query.limit
    query.offset = offset
    const meta = await metaResource.list({ path: route.path })
    const metaSeo = meta.data
    const { data, total } = await blogArticleResource.list(query)
    query.total = total
    const listData = data
    const structuredData = {}
    return {
      listData,
      query,
      metaSeo,
      structuredData
    }
  },
  head () {
    if (!this.metaSeo) {
      return {}
    }
    return {
      title: (this.metaSeo?.title || ''),
      meta: [
        { hid: 'title', name: 'title', content: (this.metaSeo?.title || '') },
        { hid: 'description', name: 'description', content: (this.metaSeo?.description || '') },
        { hid: 'keywords', name: 'keywords', content: (this.metaSeo?.keyword || '') },
        { hid: 'og:type', property: 'og:type', content: 'website' },
        { hid: 'og:title', property: 'og:title', content: (this.metaSeo?.title || '') },
        { hid: 'og:url', property: 'og:url', content: (process.env.NUXT_ENV_WEB_URL + this.$route.fullPath) },
        { hid: 'og:description', property: 'og:description', content: (this.metaSeo?.description || '') },
        { hid: 'og:image', property: 'og:image', content: (this.metaSeo?.image || '') },
        { hid: 'twitter:card', property: 'twitter:card', content: 'summary_large_image' },
        { hid: 'twitter:title', property: 'twitter:title', content: (this.metaSeo?.title || '') },
        { hid: 'twitter:url', property: 'twitter:url', content: (process.env.NUXT_ENV_WEB_URL + this.$route.fullPath) },
        { hid: 'twitter:description', property: 'twitter:description', content: (this.metaSeo?.description || '') },
        { hid: 'twitter:image', property: 'twitter:image', content: (this.metaSeo?.image || '') }
      ],
      link: [
        { rel: 'canonical', href: process.env.NUXT_ENV_WEB_URL + this.$route.fullPath }
      ],
      script: [{ type: 'application/ld+json', json: this.structuredData }]
    }
  },
  watch: {
    '$route.query' () {
      this.loadData()
    }
  },
  methods: {
    async loadData () {
      const { data, total } = await blogArticleResource.list(this.query)
      this.query.total = total
      this.listData = data
    },
    paginationData (page) {
      if (page >= 1) {
        const offset = (page - 1) * this.query.limit
        this.query.offset = offset
        this.$router.push({ path: this.$route.path, query: { page } })
      }
    }
  }
}
</script>
<style lang="scss">
.ml-blog{
  .slick-slider{
    .slick-list{
      .banner-item{
        max-height: 400px;
        object-fit: cover;
      }
    }
    .slick-dots{
      bottom:15px;
      li{
        &.slick-active{
          button{
            &::before{
              opacity: 1;
            }
          }
        }
        button{
          &::before{
            color: white;
            font-size: 15px;
          }
        }
      }
    }
  }
  .blog-list{
    .blog-item{
      margin-top:5px;
      @media (min-width:992px) {
        margin-top:10px;
      }
      @media (min-width:1366px) {
        margin-top:15px;
      }
      &.topic{
        padding-bottom: 20px;
        border-bottom: 6px solid #EDEDED;
        @media (min-width:992px) {
          padding-bottom: 0;
          border-bottom: 0;
        }
        @media (min-width:1366px) {}
      }
      &:first-child{
        .blog-category{
          padding-bottom:0;
          border-bottom: 0;
        }
      }
      &:nth-child(2){
        margin-top: 5px;
        @media (min-width:992px) {
          margin-top: 10px;
        }
        @media (min-width:1366px) {
          margin-top: 15px;
        }
      }
      &:last-child{
        margin-bottom: 30px;
        @media (min-width:992px) {
        }
        @media (min-width:1366px) {
          margin-bottom: 48px;
        }
        .blog-category{
          padding-bottom:0;
          border-bottom: 0;
        }
      }
    }
  }
}
</style>

<template>
  <div class="container ml-blog-search">
    <div class="row align-items-center ">
      <div class="col-sm-6 col-lg-8">
        <div class="breadcrumd-custom">
          <nuxt-link :to="isBlogApp ? '/blog-app' : '/blog'">Blog</nuxt-link>
          <img src="~/mlearn/icons/breadcrumd.svg" alt="" class="icon" />
          <span class="active">Tìm kiếm</span>
        </div>
      </div>
      <div class="col-sm-6 col-lg-4">
        <FormSearch :value="keyword" @enter="searchData" placeholder="Tìm bài viết"/>
      </div>
      <div class="col-12">
        <div class="search-title">Kết quả tìm kiếm</div>
        <div class="search-result py-2 text-primary" v-if="query.keyword">Có {{ query.total }} kết quả tìm kiếm cho từ khóa <b>"{{query.keyword}}"</b></div>
      </div>
    </div>
    <div class="blog-list">
      <BlogItem v-for="item in listData" :key="item.id" :item="item" :keyword="query.keyword"/>
      <div v-if="listData.length < query.total" class="load-more text-center pointer bg-white" @click="loadData">Xem thêm</div>
    </div>
  </div>
</template>
<script>
import FormSearch from '~/components/mlearn/select/form-search.vue'
import BlogItem from '~/components/mlearn/blog/item.vue'
import Resource from '~/common/api/resource'
const blogArticleResource = new Resource('blog/article')
export default {
  components: {
    BlogItem,
    FormSearch
  },
  layout: 'mlearn',
  async asyncData ({ isDev, route, store, env, params, query, req, res, redirect, error }) {
    const keyword = query.q || ''
    const query_ = { limit: 6, offset: 0, total: 0, keyword }
    const { data, total } = await blogArticleResource.list(query_)
    query_.total = total
    query_.offset += data.length
    const listData = []
    listData.push(...data)
    return {
      keyword,
      listData,
      query: query_
    }
  },
  computed: {
    isBlogApp () {
      return /blog-app/.test(this.$route.name)
    }
  },
  watch: {
    $route () {
      this.keyword = this.$route.query.q || ''
      this.query.keyword = this.keyword
      this.query.offset = 0
      this.query.total = 0
      this.listData = []
      this.loadData()
    }
  },
  methods: {
    searchData (keyword) {
      this.keyword = keyword
      this.query.keyword = keyword
      if (!this.keyword) { return }
      this.$router.push((this.isBlogApp ? '/blog-app' : '/blog') + '/search?q=' + encodeURIComponent(this.keyword))
    },
    async loadData () {
      const { data, total } = await blogArticleResource.list(this.query)
      this.query.total = total
      this.query.offset += data.length
      this.listData.push(...data)
    }
  }
}
</script>

<style lang="scss">
.ml-blog-search{
  .search-title{
    font-weight: 500;
    font-size: 22px;
    @media (min-width: 992px){
      font-size: 24px;
    }
    @media (min-width: 1366px){
      font-size: 26px;
    }
  }
  .blog-list{
    .blog-item{
      padding-top: 15px;
      padding-bottom: 20px;
      border-bottom: 1px solid #E2E2E2;
      @media (min-width: 992px){
        padding-top: 20px;
        padding-bottom: 0;
        border-bottom: 0;
      }
      @media (min-width: 1366px){
      }
      &:first-child{
        padding-top: 5px;
      }
      &:last-child{
        margin-bottom: 30px;
      }
    }
    .load-more{
      margin-top: 40px;
      margin-bottom: 40px;
      box-shadow: 0px 1px 4px rgba(61, 61, 61, 0.54);
      padding-top:10px;
      padding-bottom:10px;
      font-weight: 500;
      font-size: 14px;
      @media (min-width: 1992px){
        font-size: 16px;
      }
    }
  }
}
</style>

<template>
  <div class="pb-5 ml-bang-xep-hang">
    <div class="container">
      <div class="breadcrumd-custom"><nuxt-link to="/">Hỏi bài</nuxt-link> <img src="~/mlearn/icons/breadcrumd.svg" alt="" class="icon" /> <span class="active">Bảng xếp hạng</span></div>
    </div>
    <div class="container container-mobile">
      <div class="row">
        <div class="col-lg-8 offset-lg-2">
          <div class="rank-content">
            <div class="rank-title">Bảng xếp hạng</div>
            <b-tabs class="rank-tabs" content-class="mt-2" fill>
              <b-tab title="Top ngày" active @click="getListBxh('RANK_IN_DAY')">
                <div class="select-date d-flex align-items-center justify-content-center justify-content-lg-start">
                  <div>
                    <img src="~/mlearn/icons/arrow-left.svg" alt="">
                  </div>
                  <el-date-picker v-model="date" type="date" :editable="false" :clearable="false" format="dd/MM/yyyy" value-format="dd/MM/yyyy" :picker-options="dateOptions" @change="getPrevBxh('RANK_IN_DAY')"></el-date-picker>
                  <div>
                    <img src="~/mlearn/icons/arrow-right.svg" alt="">
                  </div>
                </div>
              </b-tab>
              <b-tab title="Top tuần" @click="getListBxh('RANK_IN_WEEK')">
                <div class="select-date d-flex align-items-center justify-content-center justify-content-lg-start">
                  <div>
                    <img src="~/mlearn/icons/arrow-left.svg" alt="">
                  </div>
                  <el-date-picker v-model="week" type="week" :editable="false" :clearable="false" format="Tuần WW/yyyy" :picker-options="{firstDayOfWeek: 1}" @change="getPrevBxh('RANK_IN_WEEK')"></el-date-picker>
                  <div>
                    <img src="~/mlearn/icons/arrow-right.svg" alt="">
                  </div>
                </div>
              </b-tab>
              <b-tab title="Top tháng" @click="getListBxh('RANK_IN_MONTH')">
                <div class="select-date d-flex align-items-center justify-content-center justify-content-lg-start">
                  <div>
                    <img src="~/mlearn/icons/arrow-left.svg" alt="">
                  </div>
                  <el-date-picker v-model="month" type="month" :editable="false" :clearable="false" format="MM/yyyy" value-format="MM/yyyy" @change="getPrevBxh('RANK_IN_MONTH')"></el-date-picker>
                  <div>
                    <img src="~/mlearn/icons/arrow-right.svg" alt="">
                  </div>
                </div>
              </b-tab>
            </b-tabs>
            <div class="list-bxh">
              <RankItem v-if="ownerRank" class="rank-top" :item="ownerRank" :ind="ownerRank.rankIndex" />
              <RankItem v-for="(item,ind) in listData" :key="ind" :item="item" :ind="ind" />
              <NoData v-if="!listData.length" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import RankItem from '~/components/mlearn/rank/item.vue'
import Resource from '~/common/api/resource'
const rankResource = new Resource('score/rank')
export default {
  components: {
    RankItem
  },
  layout: 'mlearn',
  data () {
    return {
      listData: [],
      date: '',
      week: '',
      month: '',
      ownerRank: null
    }
  },
  computed: {
    ...mapState([
      'authenticated'
    ]),
    dateOptions () {
      const self = this
      return {
        disabledDate (date) {
          return date > self.$moment()
        }
      }
    }
  },
  created () {
    this.getListBxh()
  },
  methods: {
    async getListBxh (type = 'RANK_IN_DAY') {
      if (type === 'RANK_IN_DAY') {
        this.date = this.$moment().format('DD/MM/YYYY')
      }
      if (type === 'RANK_IN_MONTH') {
        this.month = this.$moment().format('MM/YYYY')
      }
      if (type === 'RANK_IN_WEEK') {
        this.week = this.$moment()
      }
      const query = {
        rankType: type,
        pointType: 'DEFAULT',
        limit: 30
      }
      const { data } = await rankResource.list(query)
      if (data.length) {
        let bxhData = data
        if (this.authenticated) {
          this.ownerRank = bxhData[0]
          bxhData = bxhData.slice(1).filter(b => b.userId !== this.ownerRank.userId)
        }
        this.listData = bxhData
      } else {
        this.listData = []
        this.ownerRank = null
      }
    },
    getPrevBxh (type = 'RANK_IN_DAY') {
      const query = {
        rankType: type,
        pointType: 'DEFAULT',
        limit: 30,
        date: this.date
      }
      if (type === 'RANK_IN_MONTH') {
        query.date = this.month
      }
      if (type === 'RANK_IN_WEEK') {
        query.date = this.$moment(this.week).format('YYYY') + this.parseWeek(this.$moment(this.week).format('W'))
      }
      rankResource.list(query)
        .then((response) => {
          if (response.data.length && response.status === 200) {
            let bxhData = response.data
            if (this.authenticated) {
              this.ownerRank = bxhData[0]
              bxhData = bxhData.slice(1).filter(b => b.userId !== this.ownerRank.userId)
            }
            this.listData = bxhData
          } else {
            this.listData = []
            this.ownerRank = null
          }
        })
    },
    parseWeek (week) {
      return parseInt(week) < 10 ? '0' + week : week
    }
  }
}
</script>
<style lang="scss" scoped>
  .ml-bang-xep-hang{
    .rank-content{
      background: var(--white);
      box-shadow: 0px 2px 4px rgba(202, 202, 202, 0.34);
      border-radius: 8px;
      padding:24px;
      @media (max-width:576px) {
        box-shadow: none;
        border-radius: 0;
        padding:16px;
      }
      .rank-title{
        font-weight: bold;
        font-size: 24px;
        @media (max-width:576px) {
          font-size: 20px;
        }
      }
      .list-bxh{
        padding-top: 16px;
        @media (max-width:576px) {
          padding-top: 10px;
        }
        .rank-item{
          border-bottom: 1px solid #EBEBEB;
          @media (max-width:576px) {}
          &:last-child{
            border-bottom: 0;
          }
        }
      }
    }
  }
</style>
<style lang="scss">
  .ml-bang-xep-hang{
    .rank-content{
      .rank-tabs{
        .nav-tabs{
          border-bottom: 3px solid #EBEBEB;
          .nav-item{
            .nav-link{
              color: initial;
              background: transparent;
              border: 0 !important;
              font-weight: 500;
              text-align: center;
              color: #65676B;
              font-size: 16px;
              @media (max-width:576px) {
                font-size: 14px;
              }
              &.active{
                color: var(--primary);
                font-weight: bold;
                position: relative;
                &::before{
                  position: absolute;
                  content: '';
                  width: 20px;
                  height: 2px;
                  background: var(--primary);
                  left: 50%;
                  bottom: 0;
                  transform: translateX(-50%);
                }
              }
            }
          }
        }
        .tab-content{
          .select-date{
            > img {
              margin-right: 15px;
              @media (max-width:576px) {
                margin-right: 7px;
              }
            }
            .el-date-editor{
              cursor: pointer;
              width: 130px;
              @media (max-width:576px) {
                width: 120px;
              }
              input{
                border: 0;
                cursor: pointer;
                padding-right: 0;
                height: initial;
                line-height: initial;
                @media (max-width:576px) {
                  height: auto;
                  line-height: unset;
                  padding-left: 0;
                  text-align: center;
                }
              }
              .el-input__prefix{
                .el-input__icon{
                  width: 20px;
                  line-height: unset;
                  @media (max-width:576px) {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
</style>
